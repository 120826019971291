/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  useMediaQuery,
  createTheme,
  Box,
} from "@material-ui/core";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {
  updateUserCall,
  updateUserSuccess,
  getUserInfo,
  createUserCall,
} from "../../service/account_service";
import accountActions from "../../redux/actions/accountActions";
import {
  formErrorHandling,
  formFieldsConfig,
  stateFieldConfig,
} from "../../shared/util/UserValidations";
import {
  stateList,
  birthMonth,
  birthDay,
  birthYear,
  country,
} from "../../utils/state";
import { validateUserExistence } from "../../shared/util/UserValidations";
import { getFullPath } from "../../config/retailerConfig";
import { useContext } from 'react';
import { SiteConfigContext } from '../../SiteConfigContext';
import PopUpModal from "../../shared/util/components/dialogComponent";

function FormWrapper(props) {
  const { retailerName, loyaltyConnectRetailerAccountName, loyaltyConnectRetailerIdType, fields } = props;
  const { user } = useSelector((state) => state);
  const signupConfig = props.config;
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const retailerFormFields = formFieldsConfig(props.fields, props.formType);
  const [isLoading, setLoading] = useState(false);

  const errorIcon = <ErrorOutlineOutlinedIcon style={{ fill: "#ff0000" }} />;
  const dropdownIcon = <ArrowDropDownOutlinedIcon />;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(
    stateFieldConfig(retailerFormFields, "", props.formType)
  );
  const [openPopUpModal, setOpenPopUpModal] = useState(false);
  const [popUpText, setPopUpText] = useState("");
  const [errors, setFormErrors] = useState(
    stateFieldConfig(retailerFormFields, "error")
  );
  const stateData = useState(stateList);
  const [formError, setFormError] = useState("");
  const [successMsg, setSuccessMsg] = useState(null);
  const [profileUpdErrMsg, setProfileUpdErrMsg] = useState(null);
  const siteConfig = useContext(SiteConfigContext);
  const colorTheme = siteConfig[0].components.find(
    (component) => component.block_type === "ColorTheme"
  );

  const useStyles = makeStyles((theme) => ({
    margin: {
      display: "flex",
      margin: theme.spacing(1),
      fontSize: "16px",
    },
    marginMobile: {
      display: "flex",
      fontSize: "16px",
    },
    textField: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      fontSize: "18px",
      "&>div": {
        fontSize: "18px",
        borderRadius: "8px",
      },
      "& input": {
        padding: "16px 16px",
        minHeight: isMobile ? '18px' : '27px',
        borderRadius: "8px",
      },
    },
    signInbtn: {
      display: "flex",
      alignSelf: "flex-start",
      width: 'fit-content',
      height: '100%',
      padding: '16px 48px',
      background: signupConfig.primary_color || colorTheme?.button_background_color,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      borderRadius: 240,
      gap: 16,
      color: signupConfig.button_text_color || colorTheme?.primary_button_text_color,
      fontSize: "18px",
      fontFamily: 'Switzer',
      fontWeight: '700',
      lineHeight: '27px',
      textTransform: 'none',
      marginTop: '8px',
      marginLeft: '8px',
      '&:hover': {
        background: signupConfig.primary_color || colorTheme?.button_background_color,
        cursor: 'default',
      },
    },
    signInbtnMobile: {
      display: "flex",
      alignSelf: "flex-start",
      color: signupConfig.button_text_color || colorTheme?.primary_button_text_color,
      outline: "none",
      padding: "1.2rem 1.6rem",
      fontSize: "18px",
      width: 'fit-content',
      borderRadius: 240,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      fontWeight: '700',
      lineHeight: '27px',
      background: signupConfig.primary_color || colorTheme?.button_background_color,
      fontFamily: 'Switzer',
      textTransform: 'none',
      marginTop: "-28px",
      marginLeft: "0px",
      "&:hover": {
        boxShadow: "none",
        background: signupConfig.primary_color || colorTheme?.button_background_color,
      },
      "&:focus": {
        boxShadow: "none",
        background: signupConfig.primary_color || colorTheme?.button_background_color,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    updateProfileBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    loyaltyId: {
      fontSize: "18px",
      fontFamily: "Switzer",
      color: colorTheme?.text_color,
      fontWeight: 700,
      marginLeft: 0,
      lineHeight: '27px',
      // Mobile css
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
      },
    },
    mandatoryField: {
      color: "red",
      fontFamily: "Switzer",
    },
    errorMessage: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "28px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
    errorMobile: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "12px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
    errortextfield: {
      borderRadius: "4px",
      background: "#FBEAE7",
      fontSize: "16px",
      "&>div": {
        fontSize: "16px",
        height: "48px",
      },
      "& input": {
        padding: "14.5px 14px",
        WebkitBoxShadow: "0 0 0 1000px #FBEAE7 inset",
      },
    },

    selectBox: {
      padding: "10px 0",
      fontSize: "16px",
      textAlign: "left",
      "&> :first-child": {
        padding: "4.5px 11px !important",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "92%",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    selectBoxerror: {
      padding: "10px 14px",
      fontSize: "16px",
      textAlign: "left",
      borderRadius: "4px",
      background: "#FBEAE7",
      "&> :first-child": {
        padding: "4.5px 0 !important",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "100%",
        "&:focus": {
          backgroundColor: "#FBEAE7",
        },
      },
    },
    unselectedValueBox: {
      borderRadius: "8px",
      backgroundColor: 'transparent',
      padding: "16px 16px",
      color: colorTheme?.text_color,
      '& .MuiSelect-select': {
        backgroundColor: 'transparent !important',
        color: colorTheme?.text_color,
      },
      '&.Mui-focused .MuiSelect-select': {
        backgroundColor: 'transparent',
        color: colorTheme?.text_color,
      },
      '& .MuiSelect-select:hover': {
        backgroundColor: 'transparent',
        color: colorTheme?.text_color,
      },
      '& .MuiSelect-select:active': {
        backgroundColor: 'transparent',
        color: colorTheme?.text_color,
      },
    },
    selectValueBox: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      fontSize: "16px",
      padding: "16px 16px",
      textAlign: "left",
      "&> :first-child": {
        padding: "4.5px 0",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "100%",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    selectPlaceholder: {
      textAlign: "left",
      fontSize: "16px",
      color: `${colorTheme?.text_color} !important`,
      fontStyle: "normal",
      fontWeight: "400",
      marginTop: "31px",
      opacity: "0.5",
      transform: "translate(14px, 20px) scale(1) !important",
    },
    selectMobilePlaceholder: {
      textAlign: "left",
      fontSize: "16px",
      color: `${colorTheme?.text_color} !important`,
      fontStyle: "normal",
      fontWeight: "400",
      marginTop: "28px",
      opacity: "0.5",
      transform: "translate(14px, 20px) scale(1) !important",
    },
    selectIcon: {
      color: colorTheme?.text_color,
      position: "absolute",
      right: "14px",
      cursor: "pointer",
      pointerEvents: "none",
    },
    selectValueicon: {
      color: colorTheme?.text_color,
      position: "absolute",
      right: "14px",
      cursor: "pointer",
      pointerEvents: "none",
    },
    checkAlign: {
      textAlign: "left",
      paddingLeft: "5%",
      marginTop: "12px",
    },
    checkAlignMobile: {
      textAlign: "left",
      paddingLeft: "14px",
      marginTop: "12px",
    },
    alertBoxerror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "24px",
    },
    alertBoxsucesserror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #44a047",
      background: "#5d9d5133",
      border: "1px solid #44a047",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "24px",
    },
    alertBoxsucessMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #44a047",
      background: "#5d9d5133",
      border: "1px solid #44a047",
      fontSize: "16px",
      width: "96%",
      marginLeft: "9px",
      marginTop: "10px",
    },
    alertBoxMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "96%",
      margin:'10px 0 10px 9px'
    },
    storeBlock: {
      display: "flex",
      flexDirection: "column",
    },
    storeTitle: {
      textOverflow: "ellipsis",
      paddingTop: "1px",
    },
    comboOptions: {
      fontSize: "14px",
      color: "red",
    },
    autoComplete: {
      fontSize: "16px",
    },
    option: {
      fontSize: "16px",
    },
    noOptions: {
      fontSize: "16px",
    },
    checkAlign: {
      textAlign: "left",
      paddingLeft: "5%",
      marginTop: "12px",
      display: "flex",
      alignItems: "center",
    },
    checkAlignMobile: {
      textAlign: "left",
      paddingLeft: "14px",
      marginTop: "12px",
      display: "flex",
      alignItems: "center",
    },
    mobileFormContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      margin: 0,
      padding: 0,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Set user values to profile page
    // Take cache user form cache to reduce delay.
    if (props.formType === "profile") {
      setLoading(true);
      const cachedUser = JSON.parse(localStorage.getItem("userData"))
        ? JSON.parse(localStorage.getItem("userData"))
        : user;
      setFormValues(
        stateFieldConfig(retailerFormFields, "", props.formType, cachedUser)
      );
      setLoading(false);
    }
  }, [user]);

  // Input filed values assigning
  const handleChange = (prop, field) => (event) => {
    setSuccessMsg(null);
    setProfileUpdErrMsg(null);
    let value;

    if (field === "checkbox") {
      value = event.target.checked;
      setFormValues({ ...formValues, [prop]: value });
      setFormErrors({ ...errors, [prop]: "" });
    } else {
      value = event.target.value;
      let typeCheck = false;
      let lengthCheck = false;
      if (
        prop === "phone" ||
        field.fieldMap === "phone" ||
        field.label === "Phone Number" ||
        prop === "loyaltyId" ||
        field.fieldMap === "loyaltyId"
      ) {
        typeCheck = isNaN(value);
        lengthCheck = 10 < value.length;
      }

      if (!typeCheck && !lengthCheck)
        setFormValues({ ...formValues, [prop]: value });
      setFormErrors({ ...errors, [prop]: "" });
    }
  };

  // Drop down  values Changing
  const handleSelectChange = (prop, community) => (event) => {
    const value = event.target.value;
    setFormValues({
      ...formValues,
      [prop]: community ? event.target.getAttribute("name") : value,
    });
    setFormErrors({ ...errors, [prop]: "" });
    setSuccessMsg(null);
    setProfileUpdErrMsg(null);
  };
  const formFieldMap = (data) => {
    let fieldName;
    if (data.fieldMap) fieldName = data.fieldMap;
    else {
      if (data.label === "Phone Number") {
        fieldName = "phone";
      }
      if (data.label === "Email Address") {
        fieldName = "email";
      }
    }
    return fieldName;
  };
  // Create Account Click functionality
  const handleCreate = async () => {
    setSuccessMsg(null);
    setFormErrors(null);
    let formerrors = errors;
    retailerFormFields.map((data) => {
      const validationCheck =
        (data.isRequired || data.required) && !data.disabled;
        if (validationCheck && formValues[data.name] === "") {
          formerrors = {
            ...formerrors,
            [data.name]: data.name === "terms"
              ? "You must agree to the Terms and Conditions and Privacy Policy in order to enroll"
              : `${data.label} is required`,
          };
        } else if (formValues[data.name] !== "") {
        let fieldMap = data.name;
        if (data.name === "username") {
          fieldMap = formFieldMap(data);
        }
        formerrors = {
          ...formerrors,
          [data.name]: formErrorHandling(
            formValues[data.name],
            fieldMap,
            formValues,
            data.maxLength,
            data
          ),
        };
      }
    });
    setFormErrors(formerrors);

    // Checking the state errors
    if (new Set(Object.values(formerrors)).size === 1) {
      setLoading(true);

      // Reassigning the state keys with config fieldMap values
      const formField = retailerFormFields.reduce((field, form) => {
        const nameValue = form.name;
        if (
          form.name !== "passwordTwo" &&
          form.name !== "terms" &&
          form.name !== "marketing"
        ) {
          if (form.name === "passwordOne") {
            field["password"] = formValues[nameValue];
          } else {
            field[form.name] = formValues[nameValue];
          }
        }

        if (form.isUsername === true) {
          field["username"] = formValues[nameValue];
        }

        return field;
      }, {});

      if (props.enrollmentType === "LoyaltySync") {
        try {
          await validateUserExistence(
            props.loyaltyConnectRetailerName,
            formField.username
          );
        } catch (error) {
          setLoading(false);
          setPopUpText(`We couldn't find your ${loyaltyConnectRetailerAccountName}. Please check that the ${loyaltyConnectRetailerIdType} provided is linked to your ${loyaltyConnectRetailerAccountName}. If the issue persists, contact our customer care <a href="contact/">here</a>.`);
          setOpenPopUpModal(true);
          setFormError("Loyalty account not found.");
          return;
        }
      }

      if (props.formType === "profile") {
        const updateUser = await updateUserCall(formField, retailerName);
        setLoading(false);
        if (updateUser.error) {
          const errorValue = updateUser.fields && updateUser.fields[0];
          switch (errorValue) {
            case "email":
              setProfileUpdErrMsg({
                genricErrorMsg:
                  "This email address is already linked to another account. Please use a different email address.",
              });
              break;
            case "phone":
              if (Object.keys(formValues).includes("phone")) {
                setProfileUpdErrMsg({
                  genricErrorMsg:
                    "This phone number is already linked to another account. Please use a different phone number.",
                });
              } else {
                setFormError(updateUser.error);
              }
              break;
            case "username":
              const username = fields.username;
              if (username === fields.phone) {
                setProfileUpdErrMsg({
                  genricErrorMsg:
                    "This phone number is already linked to another account. Please use a different phone number.",
                });
              } else if (username === fields.email) {
                setProfileUpdErrMsg({
                  genricErrorMsg:
                    "This email address is already linked to another account. Please use a different email address.",
                });
              } else if (username === fields.loyaltyId) {
                setProfileUpdErrMsg({
                  genricErrorMsg:
                    "Your loyalty ID is already linked to another account. Please use a different loyalty ID.",
                });
              } else {
                setProfileUpdErrMsg({ genricErrorMsg: updateUser.error });
              }
              break;
            default:
              setFormErrors({
                ...errors,
                [errorValue]: `Invalid parameter ${errorValue}`,
              });
          }
        } else {
          setSuccessMsg("Your profile has been updated successfully!");
          setProfileUpdErrMsg(null);
          dispatch(updateUserSuccess(retailerName));
        }
      } else {
        const enrollmentType = props.enrollmentType || "MyLoyalty";

        let loyaltyConnectConfig = null;
        if (enrollmentType === "LoyaltySync") {
          loyaltyConnectConfig = {
            loyaltyConnectRetailerName: props.loyaltyConnectRetailerName,
            loyaltyProgramId: props.loyaltyProgramId,
            sfmcListId: signupConfig.sfmc_list_id,
            sfmcWelcomeEmailId: signupConfig.sfmc_welcome_email_key
          };
        }
        createUserCall(
          formField,
          props.retailerName,
          enrollmentType,
          loyaltyConnectConfig
        ).then((res) => {
          if (res.error) {
            const errorValue = res.fields && res.fields[0];
            setLoading(false);
            switch (errorValue) {
              case "email":
                setPopUpText(`An account with this email address already exists. <a href="login/">Log in here</a>. If you continue to have trouble signing up, contact our customer care <a href="contact/">here</a>.`);
                setOpenPopUpModal(true);
                setFormError(
                  "An account with this email address already exists. Please log in or use a different email address to register."
                );

                break;
              case "phone":
                if (Object.keys(formValues).includes("phone")) {
                  setPopUpText(`An account already exists with this ${loyaltyConnectRetailerIdType}. <a href="login/">Log in here</a>. If you're having trouble signing up, please contact our customer care <a href="contact/">here</a>.`);
                  setOpenPopUpModal(true);
                  setFormError(
                    "An account with this phone number already exists. Please log in or use a different phone number to register."
                  );
                } else {
                  setFormError(res.error);
                }
                break;
              case "username":
                const username = fields.username;
                if (username === fields.phone) {
                  setPopUpText(`An account already exists with this ${loyaltyConnectRetailerIdType}. <a href="login/">login</a>.. If you're having trouble signing up, please contact our customer care <a href="contact/">here</a>.`);
                  setOpenPopUpModal(true);
                  setFormError(
                    "An account with this phone number already exists. Please log in or use a different phone number to register."
                  );
                } else if (username === fields.email) {
                  setPopUpText(`An account with this email address already exists. <a href="login/">login</a>.. If you continue to have trouble signing up, contact our customer care <a href="contact/">here</a>.`);
                  setOpenPopUpModal(true);
                  setFormError(
                    "An account with this email address already exists. Please log in or use a different email address to register."
                  );
                } else if (username === fields.loyaltyId) {
                  setPopUpText(`An account already exists with this ${loyaltyConnectRetailerIdType}. <a href="login/">login</a>. If you're having trouble signing up, please contact our customer care <a href="contact/">here</a>.`);
                  setOpenPopUpModal(true);
                  setFormError(
                    "Your loyalty ID is already registered. Please log in to continue or use the Forgot Password option to reset your password."
                  );
                } else {
                  setPopUpText(`We encountered an issue with your enrollment. Please try again. If the problem continues, contact our customer care <a href="contact/">here</a>.`);
                  setOpenPopUpModal(true);
                  setFormError(res.error);
                }
                break;
              default:
                setPopUpText(`We encountered an issue with your enrollment. Please try again. If the problem continues, contact our customer care <a href="contact/">here</a>.`);
                setOpenPopUpModal(true);
                setFormErrors({
                  ...errors,
                  [errorValue]: `Invalid parameter ${errorValue}`,
                });
            }
          } else {
            setLoading(false);
            localStorage.setItem("token", res);
            getUserInfo(res, props.retailerName).then((user) => {
              dispatch(accountActions.setUserLoginStatus());
              dispatch(accountActions.setUserDetails(user));
              localStorage.setItem("userData", JSON.stringify(user));
              navigate(getFullPath("home"));
            });
          }
        });
      }
    }
  };

  // Grid values changing based on the fields.
  const handleMultiColumn = (data, isMobile) => {
    let xs = "";
    if (isMobile) {
      xs = 12;
    } else if (
      data.name === "phone" ||
      data.name === "email" ||
      data.name === "username" ||
      data.name === "fullName" ||
      data.name === "password" ||
      data.name === "memberId" ||
      data.name === "firstName" ||
      data.name === "lastName" ||
      data.name === "terms" ||
      data.name === "marketing" ||
      data.name === "loyaltyId" ||
      data.name === "address1"
    ) {
      xs = 12;
    } else if (!data.isMultiColumn) {
      xs = 6;
    }
    return xs;
  };

  // Changing the field label based on the config language
  const getFieldLabel = (fields, locale) => {
    let newLabel = "";
    if (Object.keys(fields).indexOf("internationalization") > -1) {
      newLabel =
        fields.internationalization[locale] !== undefined
          ? fields.internationalization[locale]
          : fields.label;
    } else {
      newLabel = fields.label;
    }
    return newLabel;
  };

  // Checking the required field  based on the config fields
  const mandatoryField = (fields) => {
    const validation = fields.isRequired || fields.required;
    return validation;
  };

  // Adding the classe base on the device and errors
  const handleUsernameClass = (fieldValue) => {
    let txtFieldValue;
    if (errors[fieldValue]) txtFieldValue = classes.errortextfield;
    else txtFieldValue = classes.textField;
    return txtFieldValue;
  };

  // Adding the classe base on the device and errors
  const handleSelectClass = (fieldValue) => {
    let txtFieldValue;
    if (errors[fieldValue]) txtFieldValue = classes.selectBoxerror;
    else txtFieldValue = classes.selectBox;
    return txtFieldValue;
  };

  return (
    <Grid
      tabIndex={0}
      container
      className={isMobile ? classes.mobileFormContainer : ""}
    >
      {retailerFormFields.length > 0 &&
        retailerFormFields.map((fields, idx) => (
          <Grid item xs={handleMultiColumn(fields, isMobile)}>
            {/* Textfield start here */}
            {fields.field === "textField" && (
              // Excluding the fields based on the formtype and its relavant disable field value of the config
              <FormControl
                className={isMobile ? classes.marginMobile : classes.margin}
                variant="outlined"
              >
                <label htmlFor={getFieldLabel(fields)}>
                  <FormHelperText
                    className={classes.loyaltyId}
                    id="outlined-weight-helper-text"
                    aria-live="polite"
                  >
                    {getFieldLabel(fields)}{" "}
                    {mandatoryField(fields) && (
                      <span className={classes.mandatoryField}>*</span>
                    )}
                  </FormHelperText>
                </label>

                <TextField
                  variant="outlined"
                  id={getFieldLabel(fields)}
                  // Disabling the fields based on the formtype and its relavant disable field value of the config
                  disabled={fields.disabled}
                  type={
                    fields.name === "passwordOne" ||
                    fields.name === "passwordTwo" ||
                    fields.name === "password"
                      ? "password"
                      : "string"
                  }
                  error={errors[fields.name] !== ""}
                  value={formValues[fields.name]}
                  onChange={handleChange(fields.name, fields)}
                  // Changing the End icon of the text field based on the error
                  InputProps={
                    errors[fields.name]
                      ? {
                          endAdornment: (
                            <InputAdornment position="start">
                              {errorIcon}
                            </InputAdornment>
                          ),
                        }
                      : ""
                  }
                  className={handleUsernameClass(fields.name)}
                />
              </FormControl>
            )}
            {/* Textfield end  here */}

            {/* SelectBox start  here */}
            {fields.field === "dropdown" && (
              // Excluding the fields based on the formtype and its relavant disable field value of the config
              <FormControl
                variant="outlined"
                className={isMobile ? classes.marginMobile : classes.margin}
              >
                <label htmlFor={`${idx}-dropdown`}>
                  <FormHelperText
                    className={classes.loyaltyId}
                    id="outlined-weight-helper-text"
                  >
                    {getFieldLabel(fields)}{" "}
                    {mandatoryField(fields) && (
                      <span className={classes.mandatoryField}>*</span>
                    )}
                  </FormHelperText>
                </label>

                <Select
                  labelId={`${idx}-dropdown`}
                  // Disabling the fields based on the formtype and its relavant disable field value of the config
                  disabled={fields.disabled}
                  id={`${idx}-dropdown`}
                  value={formValues[fields.name]}
                  error={errors[fields.name] !== ""}
                  inputProps={{
                    "aria-label": "Without label",
                    fontSize: "16px",
                  }}
                  // Changing the End icon of the Select box based on the error
                  IconComponent={() =>
                    errors[fields.name] ? (
                      <InputAdornment
                        position="end"
                        className={
                          formValues[fields.name]
                            ? classes.selectValueicon
                            : classes.selectIcon
                        }
                      >
                        {errorIcon}
                      </InputAdornment>
                    ) : (
                      <InputAdornment
                        position="end"
                        className={
                          formValues[fields.name]
                            ? classes.selectValueicon
                            : classes.selectIcon
                        }
                      >
                        {dropdownIcon}
                      </InputAdornment>
                    )
                  }
                  className={
                    `${formValues[fields.name] 
                      ? classes.selectValueBox 
                      : handleSelectClass(fields.name)} 
                      ${classes.unselectedValueBox}`}

                  onChange={handleSelectChange(fields.name)}
                  onKeyDown={handleSelectChange(fields.name)}
                  displayEmpty
                  renderValue={(value) => {
                    if (!value) {
                      return <span class={classes.selectPlaceholder}>Select</span>;
                    }
                    return value;
                  }}
                >
                  {fields.name === "state" &&
                    stateData[0].map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}

                  {fields.name === "birthMonth" &&
                    birthMonth.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                  {fields.name === "birthDay" &&
                    birthDay.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                  {fields.name === "birthYear" &&
                    birthYear.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                  {fields.name === "country" &&
                    country.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {/* SelectBox end  here */}

            {/* checkbox starts here */}
            {fields.field === "checkbox" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  my: isMobile ? "0px" : "8px",
                  pl: isMobile ? "12px" : "0px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(formValues[fields.name])}
                      onChange={(event) =>
                        handleChange(fields.name, "checkbox")(event)
                      }
                      name={fields.name}
                      sx={{
                        p: 0,
                        mr: { xs: "8px", sm: "8px" },
                        ml: { xs: "0px", sm: "18px" },
                        color: "black",
                        "&.Mui-checked": {
                          color: props.config.primary_color,
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: { xs: 20, sm: 21 },
                          mt: { xs: "0px" },
                        },
                      }}
                    />
                  }
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontFamily: "Switzer",
                        fontWeight: 400,
                        lineHeight: "18px",
                        textAlign: "left",
                        wordBreak: "break-word",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {fields.name === "terms" ? (
                        <>
                          By clicking 'Continue,' you confirm that you have
                          read, understand, and agree to our{" "}
                          <a
                            href={
                              fields.termsLink &&
                              !fields.termsLink.startsWith("http")
                                ? getFullPath(fields.termsLink)
                                : fields.termsLink
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: props.config.primary_color,
                              textDecoration: "none",
                            }}
                          >
                            Terms and Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href={
                              fields.privacyLink &&
                              !fields.privacyLink.startsWith("http")
                                ? getFullPath(fields.privacyLink)
                                : fields.privacyLink
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: props.config.primary_color,
                              textDecoration: "none",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </>
                      ) : (
                        fields.text
                      )}{" "}
                      {mandatoryField(fields) && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </p>
                  }
                  sx={{
                    mx: 0,
                    alignItems: "flex-start",
                  }}
                />
              </Box>
            )}

            {/* checkbox ends  here */}

            {errors[fields.name] !== "" && (
              <FormHelperText
                tabIndex={0}
                className={
                  isMobile ? classes.errorMobile : classes.errorMessage
                }
                id="outlined-weight-helper-text"
              >
                {errors[fields.name]}
              </FormHelperText>
            )}
          </Grid>
        ))}
      {successMsg && (
        <Grid item xs={12}>
          <FormHelperText
            tabIndex={0}
            className={
              isMobile
                ? classes.alertBoxsucessMobile
                : classes.alertBoxsucesserror
            }
            id="outlined-weight-helper-text"
          >
            {successMsg}
          </FormHelperText>
        </Grid>
      )}
      <Grid item xs={12}>
        {profileUpdErrMsg && (
          <FormHelperText
            tabIndex={0}
            className={
              isMobile ? classes.alertBoxMobile : classes.alertBoxerror
            }
            id="outlined-weight-helper-text"
          >
            {profileUpdErrMsg && profileUpdErrMsg.errorMsg ? (
              <span>{profileUpdErrMsg.errorMsg}</span>
            ) : (
              profileUpdErrMsg.genricErrorMsg
            )}
          </FormHelperText>
        )}
      </Grid>

      {/* Changing the Button based on the form Type */}
      <Grid
        className={props.formType === "profile" ? classes.updateProfileBtn : ""}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: props.formType === "profile" ? "flex-end" : "flex-start",
          paddingBottom: "10px",
        }}
      >
        {/* signup Confirmation text */}
        {props.formType !== "profile" && (
          <Typography
            tabIndex={0}
            style={{ whiteSpace: "unset", textAlign: "left" }}
            className={classes.createAccount}
            noWrap
            data-testid="signUp"
          >
            {props?.confirmTxt}
          </Typography>
        )}

        <Button
          tabIndex={0}
          variant="contained"
          className={isMobile ? classes.signInbtnMobile : classes.signInbtn}
          onClick={handleCreate}
          disabled={isLoading}
        >
          {!isLoading ? (
            <div
              data-testid={
                props.formType === "profile" ? "updateProfileBtn" : "signBtn"
              }
              className="createAccount"
            >
              {props.formType === "profile"
                ? "Save Changes"
                : props.config?.submit_btn_text || "Sign Up"}
            </div>
          ) : (
            <div className="loader-button" />
          )}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {formError !== "" && formError !== undefined && (
          <FormHelperText
            tabIndex={0}
            className={
              isMobile ? classes.alertBoxMobile : classes.alertBoxerror
            }
            id="outlined-weight-helper-text"
          >
            {formError}
          </FormHelperText>
        )}
      </Grid>
        {openPopUpModal && (
          <PopUpModal
          open={openPopUpModal}
          popUpInfoIcon={errorIcon}
          popUpText={popUpText}
          popUpTitle={"Action Failed"}
          colourButtonName={"Try again"}
          whiteButtonName={"Close"}
          mainColor={signupConfig.primary_color || colorTheme?.button_background_color}
          closeModal={() => setOpenPopUpModal(false)}
          colourButtonAction={() => setOpenPopUpModal(false)}
          whiteButtonAction={() => setOpenPopUpModal(false)}
        />
      )}
    </Grid>
  );
}

export default FormWrapper;
