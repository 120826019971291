import React from "react";
import { useSelector } from "react-redux";
import HeaderNavigation from "../blocks/headerNavigation";
import FooterV2 from "../footerV2/footer";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";
import getComponent from "../shared/util/index";

function ContactPage({ page }) {
  const { isLogin } = useSelector((state) => state.user);

  const retailerConfig = page[0];
  const contactPageConfig = page.find(
    (page) =>
      page.slug === "contact" &&
      (isLogin
        ? ["both", "authenticated"].includes(page.auth_visibility)
        : ["both", "unauthenticated"].includes(page.auth_visibility))
  );
  const backgroundColor = contactPageConfig.site_color_background ? contactPageConfig.site_color_background : retailerConfig.site_color_background ? retailerConfig.site_color_background : '#fff';

  const header = retailerConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const footer = retailerConfig.components.find(
    (component) => component.block_type === "FooterBlock"
  );

//   if (!shouldRenderComponent(contactPageConfig.auth_visibility)) {
//     return null;
//   }

  return (
    <div style={{backgroundColor: backgroundColor}}>
      {header && <HeaderNavigation page={retailerConfig} />}
      <div id="App-content">
        {contactPageConfig &&
          contactPageConfig.components.map((blockConfig) => (
            <>
              {getComponent(
                blockConfig.block_type,
                contactPageConfig,
                blockConfig,
                retailerConfig
              )}
            </>
          ))}
      </div>
      {footer && <FooterV2 page={retailerConfig} />}
    </div>
  );
}

export default ContactPage;