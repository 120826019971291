import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import accountActions from "../redux/actions/accountActions";
import { formStyles } from "../styles/cpg/form_styles";
import {
  login,
  getUserInfo,
  getLoyaltyToken,
} from "../service/account_service";
import { isLoggedIn } from "../shared/util/ComponentAuth";
import { getFullPath } from "../config/retailerConfig";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { validatePassword } from "../shared/util/UserValidations";
import PopUpModal from "../shared/util/components/dialogComponent";
import {
  getProgramDetials,
  enrollProgram,
} from "../service/myloyalty_services";
import loyalityOffersActions from "../redux/actions/loyalityOffersActions";

function LoginWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isLoggedIn()) {
      const params = new URLSearchParams(location.search);
      const focusParam = params.get("focus");

      const homeUrl = getFullPath("/home");
      const redirectUrl = focusParam
        ? `${homeUrl}?focus=${focusParam}`
        : homeUrl;

      navigate(redirectUrl);
    }
  }, []);

  const component = props.page.components.filter(
    (item) => item.block_type === "LoginBlock"
  )[0];
  const { primary_color, text_color, logo_Alignment } = component;
  const loginConfig = props.page.components.find(
    (component) => component.block_type === "LoginBlock"
  );
  const [values, setValues] = useState({
    userName: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    errorUsername: "",
    errorPassword: "",
    errorMessage: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [openPopUpModal, setOpenPopUpModal] = useState(false);
  const [popUpText, setPopUpText] = useState("");
  const errorIcon = <ErrorOutlineOutlinedIcon style={{ fill: "#ff0000" }} />;

  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: 564,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    loginPage: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    loginLogo: {
      width: "240px",
      height: "auto",
      margin: "1rem 4rem",
      marginBottom: "40",
      objectFit: "contain",

      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    signIntxt: {
      fontSize: "60px",
      fontFamily: "switzer",
      fontWeight: "900",
      marginBottom: "64px",
    },
    createAccount: {
      padding: "10px",
      fontSize: "18px",
      lineHeight: "27px",
      fontWeight: "400",
      fontFamily: "switzer",
    },
    createTxt: {
      color: primary_color,
      cursor: "pointer",
      fontFamily: "switzer",
      fontWeight: "700",
    },
    subText: {
      padding: "10px",
      fontSize: "14px",
      fontFamily: "switzer",
    },
    submitBtn: {
      color: props.page.color_text,
      outline: "none",
      padding: "1rem 1.6rem",
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "switzer",
      width: "486px",
      maxWidth: "486",
      boxShadow: "none",
      borderRadius: "240px",
      lineHeight: "19.8px",
      backgroundColor: props.page.color_background,
      textTransform: "capitalize",
      marginTop: "24px",
      marginBottom: "40px",

      "&:hover": {
        boxShadow: "none",
        backgroundColor: props.page.color_background,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: props.page.color_background,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "1.2rem 1.6rem",
      },
    },
    forgotTxt: {
      fontSize: "18px",
      fontFamily: "switzer",
      lineHeight: "19.8px",
      fontWeight: "700",
      marginTop: "24px",
      color: primary_color,
      cursor: "pointer",

      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
      },
    },
  }));
  const classes = useStyles();
  const globalClasses = formStyles(props.page);
  const dispatch = useDispatch();

  // input filed values assigning
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ errorUsername: "", errorPassword: "", errorMessage: "" });
  };

  const handleFormFieldclass = (fieldValue) => {
    let txtFiledValue;
    if (errors[fieldValue]) txtFiledValue = globalClasses.errortextfield;
    else txtFiledValue = globalClasses.textField;
    return txtFiledValue;
  };

  const handleSignIn = async () => {
    setLoading(true);
    const emailInput = document.getElementById("Email");
    let userErr = "";
    if (emailInput.type === "email" && emailInput.checkValidity()) {
      userErr = "";
    } else {
      userErr = "Enter a valid email address";
    }
    const passwordErr = validatePassword(values.password, true);

    // Clear Errors
    setErrors({ errorUsername: "", errorPassword: "", errorMessage: "" });

    if (userErr == "" && passwordErr == "") {
      try {
        const loginRes = await login(
          values.userName,
          values.password,
          props.page.dpn_instance.dpn_instance
        );

        const { token } = loginRes.data;
        if (token) {
          dispatch(accountActions.setUserLoginStatus());
          localStorage.setItem("token", token);

          const user = await getUserInfo(
            token,
            props.page.dpn_instance.dpn_instance
          );
          dispatch(accountActions.setUserDetails(user));
          localStorage.setItem("userData", JSON.stringify(user));

          if (props.page.retailer_dpn_instance.dpn_instance) {
            const retailerToken = await getLoyaltyToken(
              user?.username || values.userName,
              props.page.retailer_dpn_instance.dpn_instance
            );

            if (retailerToken) {
              localStorage.setItem("retailerToken", retailerToken);
              const programDetails = await getProgramDetials(
                props.page.retailer_dpn_instance.dpn_instance,
                props.page.retailer_dpn_instance.loyalty_program_id,
                retailerToken
              );

              if (programDetails.status !== "clipped") {
                const response = await enrollProgram(
                  props.page.retailer_dpn_instance.dpn_instance,
                  props.page.retailer_dpn_instance.loyalty_program_id,
                  retailerToken
                );
                if (!response.error) {
                  if (response.result) {
                    dispatch(
                      loyalityOffersActions.enrollProgramSuccess(
                        props.page.retailer_dpn_instance.loyalty_program_id
                      )
                    );
                  }
                } else {
                  throw new Error("Program status is not clipped");
                }
              }
            } else {
              throw new Error("No token received from getLoyaltyToken");
            }
          }

          const params = new URLSearchParams(location.search);
          const focusParam = params.get("focus");
          const homeUrl = getFullPath("/home");
          const redirectUrl = focusParam
            ? `${homeUrl}?focus=${focusParam}`
            : homeUrl;

          navigate(redirectUrl);
        } else {
          throw new Error("No token received from login");
        }
      } catch (error) {
        console.error("Error during login process:", error);

        if (
          error.message === "Program status is not clipped" ||
          error.message === "No token received from getLoyaltyToken"
        ) {
          setPopUpText(
            `We encountered an issue with your login. Please try again. If this keeps happening, contact our customer care <a href="contact/">here</a>.`
          );
        } else {
          setPopUpText(
            `The account with these credentials doesn't exist. Please check your email and password, then try again. If you're still unable to log in, contact our customer care <a href="contact/">here</a>.`
          );
          setErrors({
            ...errors,
            errorMessage: "Incorrect username or password",
          });
        }

        setOpenPopUpModal(true);

        localStorage.removeItem("token");
        localStorage.removeItem("retailerToken");
      } finally {
        setLoading(false);
      }
    } else {
      setErrors({
        ...errors,
        errorUsername: userErr,
        errorPassword: passwordErr,
      });
      setLoading(false);
    }
  };

  if (!loginConfig) {
    return <>Loading...</>;
  }

  return (
    <div className={classes.loginPage}>
      <Grid item xs={12} zeroMinWidth style={{ textAlign: logo_Alignment }}>
        <a onClick={() => navigate(getFullPath(""))}>
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={loginConfig.logo}
          />
        </a>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12} zeroMinWidth style={{ textAlign: logo_Alignment }}>
            <Typography
              tabIndex={0}
              className={classes.signIntxt}
              noWrap
              data-testid="signIn"
            >
              {loginConfig.page_title}
            </Typography>
            {errors.errorMessage !== "" && (
              <div className={globalClasses.alertBoxerror}>
                {errors.errorMessage}
              </div>
            )}

            <Grid item xs={12}>
              <FormControl
                className={globalClasses.formInputControl}
                variant="outlined"
              >
                <label htmlFor="Email">
                  <FormHelperText
                    style={{ fontSize: isMobile ? "16px" : "18px" }}
                    className={globalClasses.inputLabel}
                    id="outlined-weight-helper-text"
                  >
                    Email{" "}
                    <span className={globalClasses.mandatoryField}>*</span>
                  </FormHelperText>
                </label>

                <TextField
                  id="Email"
                  variant="outlined"
                  error={errors.errorUsername != ""}
                  value={values.userName}
                  type="email"
                  onChange={handleChange("userName")}
                  className={handleFormFieldclass("errorUsername")}
                />
              </FormControl>
              {errors.errorUsername !== "" && (
                <FormHelperText
                  className={globalClasses.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  {errors.errorUsername}
                </FormHelperText>
              )}

              <FormControl
                className={globalClasses.formInputControl}
                variant="outlined"
              >
                <label htmlFor="outlined-basic-password">
                  <FormHelperText
                    className={globalClasses.inputLabel}
                    style={{ fontSize: isMobile ? "16px" : "18px" }}
                    id="outlined-weight-helper-text"
                  >
                    Password{" "}
                    <span className={globalClasses.mandatoryField}>*</span>
                  </FormHelperText>
                </label>
                <TextField
                  id="outlined-basic-password"
                  variant="outlined"
                  error={errors.errorPassword != ""}
                  value={values.password}
                  type="password"
                  onChange={handleChange("password")}
                  className={handleFormFieldclass("errorPassword")}
                />
              </FormControl>
              {errors.errorPassword !== "" && (
                <FormHelperText
                  className={globalClasses.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  Please enter your password
                </FormHelperText>
              )}

              <Typography
                tabIndex={0}
                className={classes.forgotTxt}
                noWrap
                onClick={() => navigate(getFullPath("forgot"))}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(getFullPath("forgot"));
                  }
                }}
              >
                Forgot Password?
              </Typography>
              <Button
                variant="contained"
                onClick={handleSignIn}
                disabled={isLoading}
                className={classes.submitBtn}
                style={{ color: text_color, backgroundColor: primary_color }}
              >
                <div data-testid="signBtn">{loginConfig.submit_btn_text}</div>
              </Button>
            </Grid>

            <Typography
              tabIndex={0}
              className={classes.createAccount}
              noWrap
              data-testid="donthaveAccount"
            >
              Don't have an account?
              <span
                className={classes.createTxt}
                onClick={() => navigate(getFullPath("signup"))}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(getFullPath("signup"));
                  }
                }}
                data-testid="createAccount"
              >
                {" "}
                Sign Up
              </span>
            </Typography>
          </Grid>
          {openPopUpModal && (
            <PopUpModal
              open={openPopUpModal}
              popUpInfoIcon={errorIcon}
              popUpText={popUpText}
              popUpTitle={"Action Failed"}
              colourButtonName={"Try again"}
              whiteButtonName={"Close"}
              mainColor={primary_color}
              closeModal={() => setOpenPopUpModal(false)}
              colourButtonAction={() => setOpenPopUpModal(false)}
              whiteButtonAction={() => setOpenPopUpModal(false)}
            />
          )}
        </Grid>
      </Paper>
    </div>
  );
}

export default LoginWrapper;
